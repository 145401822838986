import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roleInfo: {},
};

const roleInfoSlice = createSlice({
  name: "roleInfoSlice",
  initialState,
  reducers: {
    getRoleInfoSuccess: (state, actions) => {
      state.roleInfo = actions?.payload;
    },
  },
});

export const { getRoleInfoSuccess } = roleInfoSlice.actions;

export default roleInfoSlice.reducer;
