import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  venues: [],
  currentPage: 1,
  total: 1,
  pageSize: 10,
  contact_person: "",
  floor_plan: "",
  email: "",
  address: "",
  phone: "",
};

const venueSlice = createSlice({
  name: "venueSlice",
  initialState,
  reducers: {
    getAllVenuesSuccess: (state, actions) => {
      state.venues = actions?.payload.data;
    },
    getEachVenueSuccess: (state, actions) => {
      const { contact_person, email, address, phone, floor_plan } =
        actions?.payload;
      state.contact_person = contact_person;
      state.email = email;
      state.floor_plan = floor_plan;
      state.phone = phone;
      state.address = address;
    },
  },
});

export const { getAllVenuesSuccess, getEachVenuetSuccess } = venueSlice.actions;

export default venueSlice.reducer;
