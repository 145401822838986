import { message } from "antd";
import { NOTIFICATION } from "../constants/url";
import { catchErrors } from "../utils/catchErrors";
import { genericApiCall, getApiCall } from "../utils/servicesBaseUtil";
import {
  getAllNotificationsSuccess,
  setUnreadCount,
  getAllUnreadNotificationsSuccess,
} from "../store/slice/notifications.slice";

export const getAllNotificationsApi = ({
  search,
  finalCallback,
  page,
  pageSize,
  successCallback,
}) => {
  return (dispatch) => {
    let url = `${NOTIFICATION}?page=${page ?? 1}`;
    if (search) {
      url = `${url}&search=${search}`;
    }
    if (pageSize) {
      url = `${url}&per_page=${pageSize ?? window.DEFAULT_PAGE_SIZE}`;
    }
    getApiCall({ url: url })
      .then((responseJson) => {
        if (responseJson?.success) {
          successCallback(responseJson);
          dispatch(getAllNotificationsSuccess(responseJson?.data));
        }
      })
      .catch((error) => {
        catchErrors(error, url);
      })
      .finally(() => {
        if (finalCallback) {
          finalCallback();
        }
      });
  };
};

export const getAllUnreadNotificationsApi = ({
  search,
  finalCallback,
  page,
  pageSize,
  successCallback,
}) => {
  return (dispatch) => {
    let url = `${NOTIFICATION}/unread/?page=${page ?? 1}`;
    if (search) {
      url = `${url}&search=${search}`;
    }
    if (pageSize) {
      url = `${url}&per_page=${pageSize ?? window.DEFAULT_PAGE_SIZE}`;
    }
    getApiCall({ url: url })
      .then((responseJson) => {
        if (responseJson?.success) {
          successCallback(responseJson);
          dispatch(getAllUnreadNotificationsSuccess(responseJson?.data));
          dispatch(setUnreadCount(responseJson?.data.length));
        }
      })
      .catch((error) => {
        catchErrors(error, url);
      })
      .finally(() => {
        if (finalCallback) {
          finalCallback();
        }
      });
  };
};

export const markNotificationAsRead = ({
  // userNotificationId,
  finalCallback,
  successCallback,
  failureCallback,
}) => {
  return (dispatch) => {
    let url = `notification/read`;

    genericApiCall({ url: url, method: "PUT" })
      .then((responseJson) => {
        if (responseJson?.success) {
          message.success(responseJson?.message);
          successCallback();
        } else {
          message.error(responseJson?.message);
          failureCallback(responseJson?.data?.errors);
        }
      })
      .catch((error) => {
        catchErrors(error, url); // Make sure to define catchErrors function
      })
      .finally(() => {
        finalCallback();
      });
  };
};
