import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("aioToken");

const initialState = {
  token: token ? token : undefined,
  id: 0,
  name: "",
  profile_pic: "",
  email: "",
  roleName: "",
  permissions: "",
  phone: "",
  role: "",
};

const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    storeUserDetails: (state, actions) => {
      state.userDetails = actions?.payload;
    },

    storeToken: (state, actions) => {
      state.token = actions?.payload;
    },

    getAllProfilesSuccess: (state, actions) => {
      const { id, profile_pic, name, email, roleName, permission, phone } =
        actions?.payload;
      state.id = id;
      state.profile_pic = profile_pic;
      state.name = name;
      state.email = email;
      state.roleName = roleName;
      state.permissions = permission;
      state.phone = phone;
    },
  },
});

export const {
  getAllProfilesSuccess,
  storeUserDetails,
  storeToken,
  updateFunction,
} = profileSlice.actions;

export default profileSlice.reducer;
