import { combineReducers } from "redux";

import profileSlice from "./slice/profile.slice";
import eventsSlice from "./slice/events.slice";
import vendorsSlice from "./slice/vendors.slice";
import setupSlice from "./slice/setup.slice";
import togglersSlice from "./slice/togglers.slice";
import clientsSlice from "./slice/clients.slice";
import countSlice from "./slice/count.slice";
import updateClientIdSlice from "./slice/updateClientId";
import clientInfoSlice from "./slice/clientInfo.slice";
import usersSlice from "./slice/users.slice";
import notificationsSlice from "./slice/notifications.slice";
import vendorInfoSlice from "./slice/vendorInfo.slice";
import venueInfoSlice from "./slice/venueInfo.slice";
import roleInfoSlice from "./slice/roleInfo.slice";
import hallSlice from "./slice/hall.slice";
import roleSlice from "./slice/role.slice";
import venueSlice from "./slice/venue.slice";
const rootReducer = combineReducers({
  profile: profileSlice,
  users: usersSlice,
  events: eventsSlice,
  vendors: vendorsSlice,
  setup: setupSlice,
  togglers: togglersSlice,
  clients: clientsSlice,
  count: countSlice,
  updateClientId: updateClientIdSlice,
  clientInfo: clientInfoSlice,
  vendorInfo: vendorInfoSlice,
  venueInfo: venueInfoSlice,
  roleInfo: roleInfoSlice,
  roles: roleSlice,
  notifications: notificationsSlice,
  halls: hallSlice,
  venues: venueSlice,
});

export default rootReducer;
