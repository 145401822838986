// export const BASE_URL = "http://127.0.0.1:8000/";
export const BASE_URL = "https://backend.clovereventsnepal.com/public/";
export const MEDIA_URL = BASE_URL + "storage/uploads/";

export const LOGIN = "login";
export const FORGOT_PASSWORD = "password/email";
export const REGISTER = "register";
export const LOGOUT = "logout";

export const ROLE = "role";

export const PROFILE = "profile";
export const USER = "user";
export const NOTIFICATION = "notification";

export const EVENT = "event";

export const VENDOR = "vendor";

export const HALL = "hall";

export const FUNCTION = "function";

export const TASK = "task";

export const SETUP = "setup";

export const UPLOAD_MEDIA = "api/file/upload";
export const CLIENT = "client";

export const MYDAY = "my-day";

export const VENUE = "venue";
