import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendorInfo: {},
};

const vendorInfoSlice = createSlice({
  name: "vendorInfoSlice",
  initialState,
  reducers: {
    getVendorInfoSuccess: (state, actions) => {
      state.vendorInfo = actions?.payload;
    },
  },
});

export const { getVendorInfoSuccess } = vendorInfoSlice.actions;

export default vendorInfoSlice.reducer;
