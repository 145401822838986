import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("aioToken");

const initialState = {
  token: token ? token : undefined,
  name: "",
  permission: "",
  roles: [],
  permissions: [],
};

const roleSlice = createSlice({
  name: "roleSlice",
  initialState,
  reducers: {
    storeRoleDetails: (state, actions) => {
      state.userDetails = actions?.payload;
    },
    storeToken: (state, actions) => {
      state.token = actions?.payload;
    },

    getAllRolesSuccess: (state, actions) => {
      // const { total, pageSize } = actions?.payload;
      // state.total = total;
      // state.roles = actions?.payload?.data;
      // state.pageSize = pageSize;

      state.roles = actions?.payload.data;
    },

    getAllPermissionsSuccess: (state, actions) => {
      const { permissions, total, pageSize } = actions?.payload;
      state.total = total;
      state.permissions = permissions;
      state.pageSize = pageSize;
    },
  },
});

export const {
  storeRoleDetails,
  storeToken,
  updateFunction,
  getAllRolesSuccess,
  getAllPermissionsSuccess,
} = roleSlice.actions;

export default roleSlice.reducer;
