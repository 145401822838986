import React, { Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { routes } from "../src/configs/route.config";
import Layout from "./components/layout";
import "antd/dist/antd.min.css";
import RequireAuth from "./components/requireAuth";
import { useAppSelector } from "./hooks/reduxHooks";
import { Login } from "./app/asyncComponents";
import * as Async from "./app/asyncComponents";
import { useAppDispatch } from "./hooks/reduxHooks";
import { useEffect } from "react";
import {
  getAllNotificationsApi,
  getAllUnreadNotificationsApi,
} from "./services/notifications.service";
import {
  getAllNotificationsSuccess,
  getAllUnreadNotificationsSuccess,
} from "./store/slice/notifications.slice";
import { updateTask } from "./store/slice/events.slice";
import { setUnreadCount } from "./store/slice/notifications.slice";
import "./echo.js";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function App() {
  const state = useAppSelector((state) => state);
  const { profile_pic, name, email, phone, role } = state.profile;
  const { unreadCount, unreadNotifications } = state.notifications;
  const { events } = state.events;
  // const { tasks } = state.tasks;
  const { task_updated } = state.events;

  const dispatch = useAppDispatch();

  const [eventData, setEventData] = useState(null);
  useEffect(() => {
    const notificationChannel = window.Echo.channel("notifications");

    notificationChannel.listen("NotificationEvent", (event) => {
      setEventData(event.eventData);
      // Handle the notification and event data on the frontend
    });

    return () => {
      notificationChannel.stopListening("NotificationEvent");
    };
  }, []);

  useEffect(() => {
    dispatch(
      getAllNotificationsApi({
        page: undefined,
        pageSize: undefined,
        search: undefined,
        successCallback: (data) => {
          dispatch(getAllNotificationsSuccess(data));
        },
        finalCallback: () => {},
      }),
      updateTask(false)
    );
    dispatch(
      getAllUnreadNotificationsApi({
        page: undefined,
        pageSize: undefined,
        search: undefined,
        successCallback: (data) => {
          dispatch(getAllUnreadNotificationsSuccess(data));
        },
        finalCallback: () => {},
      }),
      updateTask(false)
    );

    dispatch(setUnreadCount(unreadNotifications.length));
  }, [dispatch, eventData]);

  return (
    <>
      <Suspense
        fallback={
          <div className="fallback-container">
            <Spin indicator={antIcon} />
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* <Route path="/editProfile" element={<Async.EditProfile />} />
          <Route path="/register" element={<Async.Register />} />
          <Route path="/changePassword" element={<Async.ChangePassword />} />
          <Route path="/forgotPassword" element={<Async.ForgotPassword />} />
          <Route path="*" element={<Async.PageNotFound />} />

          <Route path="/" element={<Async.Dashboard />} />

          <Route path="/dashboard" element={<Async.Dashboard />} />

          <Route path="/my-day" element={<Async.MyDayView />} />

          <Route path="/notifications" element={<Async.Notifications />} />
          <Route path="/vendors" element={<Async.Vendors />} />
          <Route path="/clients" element={<Async.Clients />} />
          <Route path="/vendors/create" element={<Async.CreateVendors />} />

          <Route path="/vendors/:id/edit" element={<Async.CreateVendors />} />
          <Route path="/vendors/:id" element={<Async.VendorDetails />} />
          <Route path="/vendors/:id/print" element={<Async.VendorDetailsPrint />} />
          <Route path="/events" element={<Async.Events />} />
          <Route path="/events/type=:filter" element={<Async.Events />} />
          <Route path="/events/create" element={<Async.CreateEvents />} />
          <Route path="/events/:id/edit" element={<Async.CreateEvents />} />

          <Route path="/events/:id" element={<Async.EventDetails />} />

          <Route path="/events/:id/print" element={<Async.EventDetailsPrint />} />

          <Route path="/events/:id/print/:functionId" element={<Async.EventDetailsPrint />} /> */}

          <Route element={<RequireAuth allowedRoles={[1]} />}>
            {/* <Route path="/events/create" element={<Async.CreateEvents />} /> */}
          </Route>

          {/* <Route element={<RequireAuth allowedRoles={[1]} />}> */}
          {routes?.map((route, routeIndex) => {
            const {
              element: Element,
              path,
              layout: Layout,
              isEditPage,
              doTypeExist,
              requireAuth,
            } = route;
            return (
              <Route
                key={routeIndex}
                path={path}
                element={
                  <Layout pageTitle={route?.name}>
                    <Element
                      isEditPage={isEditPage}
                      doTypeExist={doTypeExist}
                    />
                  </Layout>
                }
              />
            );
          })}
          {/* </Route> */}
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
