import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: [],
  unreadNotifications: [],
  unreadCount: 0,
};

const notificationSlice = createSlice({
  name: "notificationsSlice",
  initialState,
  reducers: {
    getAllNotificationsSuccess: (state, actions) => {
      state.notifications = actions?.payload || [];
    },
    getAllUnreadNotificationsSuccess: (state, actions) => {
      state.unreadNotifications = actions?.payload || [];
    },
    setUnreadCount: (state, actions) => {
      state.unreadCount = actions?.payload || 0;
    },
  },
});

export const {
  getAllNotificationsSuccess,
  setUnreadCount,
  getAllUnreadNotificationsSuccess,
} = notificationSlice.actions;

export default notificationSlice.reducer;
