import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import { useAppSelector } from "../../hooks/reduxHooks";
import { useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Outlet } from "react-router-dom";
// const RequireAuth = ({ children }) => {
//   const { isAuthenticated } = useContext(AuthContext);

//   if (!isAuthenticated) {
//     return <Navigate to="/login" replace />;
//   }

//   return children;
// };

// export default RequireAuth;

const RequireAuth = ({ allowedRoles }) => {
  const state = useAppSelector((state) => state);
  const { role } = state.profile;

  const { auth } = useAuth(); // user data
  const location = useLocation();

  if (allowedRoles.includes(role)) {
    return <Outlet />;
  } else if (auth?.accessToken) {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
