import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  venueInfo: {},
};

const venueInfoSlice = createSlice({
  name: "venueInfoSlice",
  initialState,
  reducers: {
    getVenueInfoSuccess: (state, actions) => {
      state.venueInfo = actions?.payload;
    },
  },
});

export const { getVenueInfoSuccess } = venueInfoSlice.actions;

export default venueInfoSlice.reducer;
