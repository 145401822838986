import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  currentPage: 1,
  total: 1,
  pageSize: 10,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    getAllUsersSuccess: (state, actions) => {
      const { users, total, pageSize } = actions?.payload;
      state.total = total;
      state.users = users;
      state.pageSize = pageSize;
    },
  },
});

export const { getAllUsersSuccess } = userSlice.actions;

export default userSlice.reducer;
