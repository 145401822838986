import Echo from "laravel-echo";
import Pusher from "pusher-js";

window.io = require("socket.io-client");
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "pusher",
  key: "5354ce6c3310d09d4deb",
  cluster: "ap2",
  encrypted: true,
});
