import { createSlice } from "@reduxjs/toolkit";

const hallSlice = createSlice({
  name: "hall",
  initialState: {
    halls: [],
    error: null,
  },
  reducers: {
    deleteHallSuccess: (state, action) => {
      state.halls = state.halls.filter((hall) => hall.id !== action.payload);
      state.error = null;
    },
    deleteHallFailure: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { deleteHallSuccess, deleteHallFailure } = hallSlice.actions;
export default hallSlice.reducer;
