import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showFunctionNoteModal: false,
  showVendorCreateModal: false,
  showClientCreateModal: false,
  showVenueCreateModal: false,
  showRoleCreateModal: false,
  isVendorEditModal: false,
  isClientEditModal: false,
  isVenueEditModal: false,
  isRoleEditModal: false,
  showFunctionCreateModal: false,
  isFunctionEditModal: false,
  editFunctionData: {},
  showAssignVendorModal: false,
  assignVendorData: {},
  isUserEditModal: false,
};

const togglersSlice = createSlice({
  name: "togglersSlice",
  initialState,
  reducers: {
    toggleFunctionNoteModal: (state, actions) => {
      state.showFunctionNoteModal = actions?.payload;
    },
    toggleFunctionCreateModal: (state, actions) => {
      state.showFunctionCreateModal = actions?.payload?.showModal;
      state.isFunctionEditModal = actions?.payload?.isEdit;
    },
    toggleVendorCreateModal: (state, actions) => {
      state.showVendorCreateModal = actions?.payload?.showModal;
      state.isVendorEditModal = actions?.payload?.isEditVendor;
    },
    toggleClientCreateModal: (state, actions) => {
      state.showClientCreateModal = actions?.payload?.showModal;
      state.isClientEditModal = actions?.payload?.isEditClient;
    },

    toggleVenueCreateModal: (state, actions) => {
      state.showVenueCreateModal = actions?.payload?.showModal;
      state.isVenueEditModal = actions?.payload?.isEditVenue;
    },
    toggleRoleCreateModal: (state, actions) => {
      state.showRoleCreateModal = actions?.payload?.showModal;
      state.isRoleEditModal = actions?.payload?.isEditRole;
    },

    toggleUserEditModal: (state, actions) => {
      state.isUserEditModal = actions?.payload?.showModal;
    },
    storeEditFunctionData: (state, actions) => {
      state.editFunctionData = actions?.payload;
    },

    toggleAssignVendorModal: (state, actions) => {
      state.showAssignVendorModal = actions?.payload?.showModal;
      state.assignVendorData = actions?.payload?.data;
    },
  },
});

export const {
  toggleFunctionNoteModal,
  toggleFunctionCreateModal,
  storeEditFunctionData,
  toggleAssignVendorModal,
  toggleVendorCreateModal,
  toggleClientCreateModal,
  toggleRoleCreateModal,
  toggleUserEditModal,
  toggleVenueCreateModal,
} = togglersSlice.actions;

export default togglersSlice.reducer;
